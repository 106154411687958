#hvv-app .weatherhistory-scroll-up-btn {
    height:56px;
    margin-top: 1.5rem;
}

#hvv-app .weatherhistory-toolbar {
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
}

#hvv-app .weatherhistory-toolbar > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#hvv-app .weatherhistory-toolbar > * {
    margin-top:0.5rem;
}

#hvv-app .weatherhistory-toolbar > div span, .weatherhistory-toolbar label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#hvv-app .weatherhistory-toolbar .specific-event-select {
    min-width: 16rem;
}

#hvv-app .season-start-tooltip {
    padding: 0.5rem 0 0;
}

#hvv-app .season-start-table {
    margin-top: 0;
    margin-bottom: 0;
}
#hvv-app .season-start-table thead > tr > th {
    padding: 0.5rem 1rem;
    background-color: #F5F6F7;
}
#hvv-app .season-start-table tbody > tr > td {
    padding: 0.5rem 1rem;
    background-color: #FFF;
}

#hvv-app .season-start-table-season {
    display:flex;
    align-items: center;
    grid-gap:0.5rem;
}

#hvv-app .weather-history-introduction {
    margin-top: 0.5rem;
}

@media screen and (min-width: 640px) {
    #hvv-app .weatherhistory-toolbar {
        flex-direction: row;
    }
    #hvv-app .weatherhistory-toolbar > div + div {
        margin-left:1.5rem;
    }
    #hvv-app .weatherhistory-toolbar > div + button {
        margin-left:1.5rem;
    }

    #hvv-app .weatherhistory-toolbar {
        flex-direction: row;
        align-items: flex-end;
    }

    #hvv-app .weatherhistory-toolbar > * {
        margin-top:1.5rem;
    }

    #hvv-app .weather-history-introduction {
        margin-top: 1.25rem;
    }
}
