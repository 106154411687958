#hvv-app .carousel {
    margin-bottom: 0;
    margin-top: 0;
    align-items: center;
    vertical-align: center;
    text-align: center;
    font-family: "Futura Medium";
}

#hvv-app .carousel .carousel-center {
    display:flex;
    width:86%;
}

#hvv-app .carousel .carousel-center > *:first-child {
    display:none;
    align-self:center;
    justify-content: space-between;
}
#hvv-app .carousel .carousel-center div {
    text-align: start;
    justify-self: flex-start;
    align-self:flex-start;
    flex:1 0 0;
}

#hvv-app .carousel .carousel-center div > span {
    color:#647078;
    font-weight: lighter;
    padding:0;
    margin:0;
}

#hvv-app .carousel .carousel-center div > h3 {
    margin:0;
}

#hvv-app .carousel-center > *:last-child {
    align-self:center;
    display:none;
}

#hvv-app .carousel .carousel-prev {
    display: none;
    color: #647078;
}

#hvv-app .carousel .carousel-next {
    display: none;
    color: #647078;
}

@media screen and (min-width: 768px) {
    #hvv-app .carousel {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    #hvv-app .carousel .carousel-center {
        width:100%;
    }
    #hvv-app .carousel .carousel-center div {
        text-align: center;
        margin: 0 0.5rem;
        justify-self: center;
        align-self:center;
        flex:1 0 0;
    }
    #hvv-app .carousel .carousel-center div > h3 {
        margin-top:0.2rem;
    }

    #hvv-app .carousel .carousel-center > *:first-child {
        display:flex;
    }
    #hvv-app .carousel .carousel-center > *:last-child {
        display:flex;
    }


    #hvv-app .carousel .carousel-prev {
        display:block;
    }
    #hvv-app .carousel .carousel-next {
        display:block;
    }
}
