#hvv-app .title-bar {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 0.5rem;
}

#hvv-app .title-bar > .title-bar-title {
    flex: 1;
}

#hvv-app .title-bar h2 {
    margin-top: 0;
}
#hvv-app h2.location-title {
    margin-top: 0.5rem;
}

#hvv-app .location-introduction-text {
    margin-top: 0.5rem;
}

#hvv-app .title-bar > .title-bar-buttons {
    flex: 1;
    margin-top: 1.5rem;
}

#hvv-app .title-bar > .title-bar-buttons > div {
    display: flex;
    flex-direction: row;
}

#hvv-app .title-bar .title-bar-select {
    margin-left:0.5rem;
    outline: none;
    box-sizing: border-box;
    margin-top: 0;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}

#hvv-app .title-bar .title-bar-select span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#hvv-app .title-bar > .title-bar-select > div:first-child {
    display: none;
}

#hvv-app .title-bar .select-location-on-map-label {
    display:none;
}

#hvv-app .title-bar #select-location-on-map > svg {
   margin-right: 0;
}

@media screen and (min-width: 512px) {
    #hvv-app .location-introduction-text {
        margin-top: 1.25rem;
    }
    #hvv-app .title-bar .select-location-on-map-label {
        display:block;
    }
    #hvv-app .title-bar #select-location-on-map > svg {
        margin-right: 0.5rem;
    }
}

@media screen and (min-width: 768px) {
    #hvv-app .title-bar {
        flex-direction: row;
        align-items: center;
    }
    #hvv-app .title-bar > .title-bar-title {
        flex: 1 1 auto;
        margin-bottom: 0;
        overflow: hidden;
    }

    #hvv-app .title-bar > .title-bar-buttons {
        flex: 1 1 33%;
        max-width: 30rem;
        min-width: 19rem;
        margin-top: 0;
        margin-bottom: 0;
    }
}
