#hvv-app .responsive-wrapper {
  position: relative;
  width: 100%;
}

#hvv-app .responsive-wrapper--inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
