#hvv-app .cards-tabs {
  margin-top: 0px;
}

#hvv-app .card {
  min-height: 300px;
}

#hvv-app .card {
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
}


#hvv-app .card:hover {
  background: #DEF1FF;
}

#hvv-app .card:focus {
  background: #DEF1FF;
}

#hvv-app .card-title-bar {
  margin-bottom:16px;
  display:flex;
  grid-gap:0.5rem;
  flex-direction: column;
}
#hvv-app .card-title-bar > *{
  margin-top:0.5rem;
}

#hvv-app .card-title-bar > h2 {
  margin-top:0px;
  line-height: 56px;
}

#hvv-app .card.info-card {
  transform: rotateY(180deg);
}

#hvv-app .card .card-title {
  display: flex;
  justify-content: space-between;
}

#hvv-app .card .card-flip {
  align-self: flex-end;
  visibility: hidden;
  margin-bottom: -15px !important;
  width:48px;
  height:32px;
}

#hvv-app .card:hover .card-flip {
  visibility: visible;
}

#hvv-app .card h3 {
  margin-top:0;
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#hvv-app .card-holder-bottom-text > p {
  margin: 0;
}

#hvv-app .card-holder-bottom-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0.5rem;
}

#hvv-app .card.selected {
    background:#DEF1FF;
}

#hvv-app .card-ball {
  align-self: center;
  max-width: 160px;
}

#hvv-app .card-info-text {
  pointer-events: none;
}

#hvv-app .card-info-text > * {
  padding:0;
  margin:0;
}

#hvv-app .card-info-text > * > * {
  margin-bottom:0;
}

#hvv-app .Monthly-averageball-circle {
  stroke-width: 2px;
  fill: none;
}

#hvv-app .cards-titlebar .cards-titlebar-tabs {
  width: 100%;
}

#hvv-app .cards-holder {
  margin-bottom: 24px;
}

#hvv-app .cards-holder .card-row {
  grid-gap: 1rem;
}

#hvv-app .cards-holder .card-info-text {
  margin-top:16px;
  text-align: center;
  width: 100%;
}

#hvv-app .cards-holder .card-info-text span {
  margin-top: 0;
  margin-right: 0.5rem;
  line-height: 24px;
}

#hvv-app .card-title-bar .flex-line-break {
  width: 100%;
  margin: 0;
}

@media screen and (min-width: 512px) {
  #hvv-app .card-title-bar {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  #hvv-app .card-title-bar > .cards-toolbar-select-month,
  #hvv-app .card-title-bar > .cards-toolbar-select-period {
    width: fit-content;
    margin-top: 1.5rem;
    margin-bottom: 0 !important;
  }

  #hvv-app .card-title-bar > .cards-toolbar-select-month {
    min-width: 9.5rem;
  }

  #hvv-app .card-title-bar > .cards-toolbar-select-period {
    min-width: 14rem;
    flex: 1;
  }

  #hvv-app .card-title-bar > * {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  #hvv-app .card-holder-bottom-text > p {
    margin: 0;
    padding: 0;
  }

  #hvv-app .card-title-bar {
    flex-wrap: nowrap;
  }

  #hvv-app .card-title-bar > .cards-toolbar-select-period {
    flex: 0;
  }

  #hvv-app .cards-holder .card-info-text {
    text-align: right;
  }
}
