#hvv-app .map-container {
  margin-top: 0.5rem;
  border: 0.0625rem solid #a6b1ba;
  height: 750px;
}

#hvv-app .map-container .map {
  height: 100%;
  width: 100%;
}

#hvv-app .leaflet-tooltip {
  color: #0D1921;
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.125rem;
  font-family: Noto Sans, Verdana, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0.125rem 0.25rem 0, rgba(0, 0, 0, 0.12) 0 0.0625rem 0.5rem 0;
}

#hvv-app .leaflet-tooltip.active-location-tooltip {
  color: #fff;
  background: #0C6BC4;
  border-color: #0C6BC4;
}
#hvv-app .leaflet-tooltip.active-location-tooltip:before {
  border-bottom-color: #0C6BC4;
}

#hvv-app .leaflet-control.zoom-control {
  margin: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0.125rem 0.25rem 0, rgba(0, 0, 0, 0.12) 0 0.0625rem 0.5rem 0;
}

#hvv-app .leaflet-control.zoom-control button + button {
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
