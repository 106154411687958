#hvv-app .loading-indicator {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

#hvv-app .loading-indicator > span {
    line-height: 1.5rem;
    font-size:1.5rem;
    text-align: center;
}

#hvv-app .graph-loader {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to right, #FFF 30%, #F5F6F7 50%, #FFF 70%);
    background-size: 200% 100%;
    animation: gradient 1s linear infinite;
}
