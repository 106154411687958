body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hvv-app .hvv-container {
  padding:0;
  max-width:980px;
  margin:auto;
}

#hvv-app .loading-app {
  margin-top: 1.5rem;
}

/* Skriv över konstig regel i polopoly som sätter padding på alla bilder, vilket bl.a. förstör kartlager i leaflet */
#hvv-app img {
  padding: 0;
}

#hvv-app .cursive {
  font-style: italic;
}

#hvv-app ul.no-bullets {
  list-style-type: none;
  padding: 0;
}
