#hvv-app .graph-table-holder {
    overflow:auto;
}

#hvv-app .graph-table-holder .graph-table-loading {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to right, #FFF 30%, #F5F6F7 50%, #FFF 70%);
    background-size: 200% 100%;
    animation: gradient 1s linear infinite;
}

@keyframes gradient {
    0% {
        background-position: 200% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

#hvv-app table.graph-table {
    margin-top:0px;
}

#hvv-app table.graph-table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

#hvv-app .graph-table td {
    white-space: nowrap;
    vertical-align: middle;
    background-color: #fff;
}

#hvv-app .graph-table .graph-table-td-icon {
    display:flex;
}
