#hvv-app .graph {
    width:100%;
    padding:0px;
}

#hvv-app .graph .graph-loading {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to right, #FFF 30%, #F5F6F7 50%, #FFF 70%);
    background-size: 200% 100%;
    animation: gradient 1s linear infinite;
}

#hvv-app .graph .uncertantity-text {
    max-width:300px;
    padding:4px;
    margin:0px;
}

#hvv-app .graph .uncertainty-toggle {
    display: flex;
    margin-top: 0.5rem;
}

@keyframes gradient {
    0% {
        background-position: 200% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

#hvv-app .graph-specific-date-notice {
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F7;
}

#hvv-app #graph-legend .graph-legend-row {
    margin-top: 0.5rem;
    grid-gap: 0.5rem;
}

#hvv-app #graph-legend .graph-legend-row .graph-legend-item {
    display:flex;
    grid-gap: 0.5rem;
    align-items: center;
}

#hvv-app .graph-custom-tooltip {
    border-radius: 0.25rem;
    background:white;
    border: 1px solid black;
    padding: 0.5rem;
    margin:8px;
}

#hvv-app .graph-custom-tooltip-label {
    font-weight: bold;
    margin:0;
    margin-bottom: 0.5rem;
    background:white;
    text-align: center;
}

#hvv-app .graph-custom-tooltip-param {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin:0;
}

#hvv-app .graph-tabs {
    margin-top: 0!important;
}

#hvv-app .graph--scroll-up-btn-container {
    position: relative;
}
#hvv-app .graph--scroll-up-btn-container > button {
    padding:0;
}



#hvv-app .graph--scroll-up-btn-container .graph--scroll-up-btn {
    position: absolute;
    right: 0;
    z-index: 10;
}

#hvv-app .graph .graph-caption {
    text-align: center;
}

#hvv-app .graph-range--container {
    margin: 1rem 1rem 2rem 1rem;
}

/* Justera default styling för rc-slider */
#hvv-app .graph-range--container .rc-slider-step {
    pointer-events: none;
}

#hvv-app  .graph-range--container .rc-slider-handle {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #0c6bc4;
    border: 0.25rem solid #fff;
    margin-top: -0.625rem;
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.5);
}

#hvv-app  .graph-range--container .rc-slider-handle:focus {
    border: 0.25rem solid #3b9cdf;
    background: #0c6bc4;
}

#hvv-app  .graph-range--container .rc-slider-handle:active {
    border: 0.25rem solid #0c6bc4;
    background: #fff;
}

#hvv-app  .graph-range--container .rc-slider-handle:disabled {
    border: 0.25rem solid #fff;
    background: #a6b1ba;
}

#hvv-app  .graph-range--container .rc-slider-tooltip-inner {
    font-size: 1rem;
    background-color: transparent;
    color: #000;
    box-shadow: none;
    padding: 0;
}

@media screen and (min-width: 768px) {
    #hvv-app .graph--scroll-up-btn-container {
        position: relative;
        height:56px;
    }
}
