#hvv-app .cardcarousel-outer {
    margin-bottom: 16px;
}

#hvv-app .cardcarousel {
    overflow: hidden;
    width: 100%;
    box-shadow: rgb(0 0 0 / 16%) 0 0.125rem 0.25rem 0, rgb(0 0 0 / 12%) 0 0.0625rem 0.5rem 0;
}

#hvv-app .cardcarousel-inner {
    white-space: nowrap;
    transition: transform 0.3s;
}

#hvv-app .cardcarousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 0;
}

#hvv-app .cardcarousel-indicators {
    display: flex;
    justify-content: center;
}

#hvv-app .cardcarousel-indicators svg {
    cursor: pointer;
}

#hvv-app .cardcarousel .card {
    box-shadow: none;
}
