#hvv-app .missing-data-badge {
    display: flex;
    align-self: center;
    margin-top: 0rem;
    margin-left:auto;
    margin-right:auto;
}

@media screen and (min-width: 768px) {
    #hvv-app .missing-data-badge {
        align-self: flex-end;
        margin-left: auto;
        margin-right: 0;
    }
}
