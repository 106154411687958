#hvv-app .graph-info-card {
    min-height: 147.5px;
    margin-top: 1.5rem;
}

#hvv-app .graph-info-card h3 {
    margin-bottom:0;
    margin-top:0;
}

#hvv-app .graph-info-card-text {
    margin-top:0.5rem;
}

#hvv-app .graph-info-card-text p, #hvv-app .graph-info-card-text ul {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
